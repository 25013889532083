import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import UserContext from "../../context/UserProvider";
import {useTranslation} from "react-i18next";


export const AnnouncementBar = (props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {setIsFirstConnection} = useContext(UserContext);
    const isEnabled = props.isEnabled
    const auth = props.auth;
    const isVerified = props.isVerified;

    const handleFirstConnection = () => {
        setIsFirstConnection(true);
        navigate('/login');
    }


    return(
        <>
            {isEnabled &&
                <Box className={`announcement-bar-wrapper yellow-bar`}>
                    <Box className={`announcement-bar yellow-bar`}>
                        <span>
                            🦺 {t("announcements.maintenanceScheduled")}
                        </span>

                    </Box>
                </Box>
            }
        </>
    )
}
