import {Box, Typography} from "@mui/material";
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';


function Maintenance () {

    return (
        <>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100vh" padding="10px" textAlign="center">

                <Box>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 1404.51 491.8"
                         height={150}
                         fill="#007dc8"
                    >
                        <path d="M128.7,157.73A16.91,16.91,0,0,1,134,145.36a18.09,18.09,0,0,1,13.35-5.27,19.46,19.46,0,0,1,13.6,5,17.19,17.19,0,0,1,0,25.24,19.42,19.42,0,0,1-13.6,5A18.09,18.09,0,0,1,134,170.11,16.91,16.91,0,0,1,128.7,157.73Zm4.17,39.94h29.4V315.3h-29.4Z"/>
                        <path d="M398.3,299.12h-.74a37.87,37.87,0,0,1-16.17,14.58,50.83,50.83,0,0,1-21.57,4.54A57.2,57.2,0,0,1,344,316a42.54,42.54,0,0,1-13.72-6.62,33.88,33.88,0,0,1-9.68-11A31.29,31.29,0,0,1,316.94,283q0-9.56,3.43-16.29a33.52,33.52,0,0,1,9.19-11.28,46.74,46.74,0,0,1,13.35-7.23,92.51,92.51,0,0,1,15.81-4,138.78,138.78,0,0,1,16.54-1.71q8.33-.37,15.68-.37h7.36v-3.19q0-11-7.6-16.54t-19.36-5.51a45.41,45.41,0,0,0-17.4,3.31A44,44,0,0,0,340,229L324.54,213.6a57,57,0,0,1,22.91-14.45,84.35,84.35,0,0,1,27.08-4.42q12.5,0,21.07,2.82a46.39,46.39,0,0,1,14.21,7.23,31.89,31.89,0,0,1,8.7,10.17,47.38,47.38,0,0,1,4.41,11.64,64.18,64.18,0,0,1,1.6,11.39q.24,5.52.24,9.68V315.3H398.3Zm-1.72-36.51h-6.13q-6.12,0-13.72.49a71.86,71.86,0,0,0-14.33,2.33,32.77,32.77,0,0,0-11.4,5.39,11.79,11.79,0,0,0-4.65,9.93,12.57,12.57,0,0,0,1.83,7,16.74,16.74,0,0,0,4.78,4.78,19.91,19.91,0,0,0,6.62,2.81,31.79,31.79,0,0,0,7.35.86q15.19,0,22.42-7.23t7.23-19.72Z"/>
                        <path d="M452.69,197.67h27.94v17.65h.49a37.71,37.71,0,0,1,17.15-15.68,55.57,55.57,0,0,1,23.28-4.91,59.38,59.38,0,0,1,24.26,4.78,54.58,54.58,0,0,1,18.38,13.11,56.07,56.07,0,0,1,11.51,19.61,73.42,73.42,0,0,1,3.92,24.26,69.62,69.62,0,0,1-4.16,24.26,60.41,60.41,0,0,1-11.64,19.6,53.89,53.89,0,0,1-18,13.11,55.22,55.22,0,0,1-23,4.78,56.94,56.94,0,0,1-14.7-1.72,50.34,50.34,0,0,1-11.27-4.41,44,44,0,0,1-8.33-5.76,41.62,41.62,0,0,1-5.64-6h-.73v70.82H452.69Zm97.53,58.82a38.33,38.33,0,0,0-2.33-13.24A33.3,33.3,0,0,0,541,232a34.75,34.75,0,0,0-50.72,0,33.14,33.14,0,0,0-6.86,11.27,38.75,38.75,0,0,0,0,26.47A33,33,0,0,0,490.31,281,34.75,34.75,0,0,0,541,281a33.2,33.2,0,0,0,6.86-11.27A38.32,38.32,0,0,0,550.22,256.49Z"/>
                        <path d="M626.92,266.78q1.47,13.23,10.29,20.58t21.32,7.35q11,0,18.5-4.53a51.24,51.24,0,0,0,13.11-11.4l21.07,15.93q-10.29,12.75-23,18.14a68,68,0,0,1-26.71,5.39,70.6,70.6,0,0,1-25-4.41,60,60,0,0,1-20.34-12.5,59.34,59.34,0,0,1-13.6-19.48,66.59,66.59,0,0,1,0-50.73,59.34,59.34,0,0,1,13.6-19.48,60,60,0,0,1,20.34-12.49,70.6,70.6,0,0,1,25-4.42A57.32,57.32,0,0,1,683.89,199a48.13,48.13,0,0,1,17.4,12.38,57.38,57.38,0,0,1,11.27,20,84.86,84.86,0,0,1,4,27.32v8.09Zm60.28-22.06q-.26-13-8.09-20.46t-21.81-7.47q-13.23,0-20.95,7.6t-9.43,20.33Z"/>
                        <path d="M303.94,270.92a61.65,61.65,0,1,1,0-28.87H274.8a34.14,34.14,0,1,0,0,28.87Z"/>
                        <path d="M303.94,270.92a61.65,61.65,0,1,1,0-28.87H274.8a34.14,34.14,0,1,0,0,28.87Z"/>
                        <path d="M274.8,270.92h29.14a61.65,61.65,0,1,1,0-28.87H274.8a34.14,34.14,0,1,0,0,28.87Z"/>
                        <circle cx="243.54" cy="256.49" r="15.49"/>
                        <path d="M806.27,286.32a32,32,0,0,0,13.84,14,40.82,40.82,0,0,0,19.28,4.82,31,31,0,0,0,9.39-1.48,28.82,28.82,0,0,0,8.41-4.2,23,23,0,0,0,6-6.55,16.43,16.43,0,0,0,2.35-8.78q0-7.9-5.07-11.86A37.48,37.48,0,0,0,848,266.05a149.48,149.48,0,0,0-16.31-3.83,70,70,0,0,1-16.31-4.94A35.11,35.11,0,0,1,802.94,248q-5.07-5.94-5.07-16.81a29.34,29.34,0,0,1,3.46-14.58,31.85,31.85,0,0,1,9-10.26,39.34,39.34,0,0,1,12.48-6.06,51.33,51.33,0,0,1,14.09-2q14.08,0,24.35,5.19a36,36,0,0,1,15.94,16.31l-13.35,7.91a31.42,31.42,0,0,0-10.5-12q-6.32-4.08-16.44-4.08a30.25,30.25,0,0,0-8.28,1.24,29.27,29.27,0,0,0-7.78,3.46,20,20,0,0,0-5.81,5.68A13.74,13.74,0,0,0,812.7,230q0,7.66,5.07,11.37a38.79,38.79,0,0,0,12.48,5.81,151.48,151.48,0,0,0,16.31,3.59,65.41,65.41,0,0,1,16.31,4.82,33.26,33.26,0,0,1,12.48,9.63q5.07,6.31,5.07,17.92a33.52,33.52,0,0,1-3.34,15.45,31.92,31.92,0,0,1-9.14,11,40.66,40.66,0,0,1-13.22,6.67,53.74,53.74,0,0,1-15.58,2.23,63.46,63.46,0,0,1-27.06-5.69,41.11,41.11,0,0,1-18.91-17.55Z"/>
                        <path d="M911.31,128.63h14.83v92.68h.5q4.44-9.39,14.83-16.19t24.22-6.79q13.35,0,21.75,4.2a35,35,0,0,1,13.22,11,41.31,41.31,0,0,1,6.67,15.69,89.15,89.15,0,0,1,1.86,18.29v68H994.36V248.25a72,72,0,0,0-1.24-13.47A35.63,35.63,0,0,0,988.8,223a22.75,22.75,0,0,0-8.65-8.28c-3.71-2-8.53-3.09-14.46-3.09a42.05,42.05,0,0,0-15.45,2.85,33.17,33.17,0,0,0-12.6,8.65,41.77,41.77,0,0,0-8.41,14.58,62.19,62.19,0,0,0-3.09,20.64v57.09H911.31Z"/>
                        <path d="M1042.31,258.39a63.61,63.61,0,0,1,4.45-24.1,57.44,57.44,0,0,1,12.35-19,54.9,54.9,0,0,1,19-12.48,64.5,64.5,0,0,1,24.22-4.45,63.65,63.65,0,0,1,24.1,4.45A55.91,55.91,0,0,1,1158,234.29a67.48,67.48,0,0,1,0,48.2A55.91,55.91,0,0,1,1126.47,314a63.84,63.84,0,0,1-24.1,4.45,64.69,64.69,0,0,1-24.22-4.45,55.19,55.19,0,0,1-19-12.48,57.55,57.55,0,0,1-12.35-19A63.61,63.61,0,0,1,1042.31,258.39Zm14.83,0a52.64,52.64,0,0,0,3.09,18.16,42.18,42.18,0,0,0,9,14.83,43.53,43.53,0,0,0,14.33,10,49.41,49.41,0,0,0,37.57,0,43.68,43.68,0,0,0,14.34-10,42.48,42.48,0,0,0,9-14.83,54.94,54.94,0,0,0,0-36.33,42.57,42.57,0,0,0-9-14.83,43.68,43.68,0,0,0-14.34-10,49.41,49.41,0,0,0-37.57,0,43.53,43.53,0,0,0-14.33,10,42.27,42.27,0,0,0-9,14.83A52.7,52.7,0,0,0,1057.14,258.39Z"/>
                        <path d="M1192.33,201.29h14.83v20h.49a38.51,38.51,0,0,1,8.65-10.25,52,52,0,0,1,11.25-7.17,56.75,56.75,0,0,1,12.73-4.2,65.22,65.22,0,0,1,13.1-1.36,63.65,63.65,0,0,1,24.1,4.45A55.91,55.91,0,0,1,1309,234.29a67.48,67.48,0,0,1,0,48.2A55.91,55.91,0,0,1,1277.48,314a63.84,63.84,0,0,1-24.1,4.45,66,66,0,0,1-13.1-1.36,56.75,56.75,0,0,1-12.73-4.2,52.34,52.34,0,0,1-11.25-7.17,38.4,38.4,0,0,1-8.65-10.26h-.49v76.37h-14.83Zm13.35,57.1a45.06,45.06,0,0,0,3.7,18.16,46.51,46.51,0,0,0,10.14,14.83,48.58,48.58,0,0,0,15.07,10,47.23,47.23,0,0,0,18.79,3.71,46.05,46.05,0,0,0,18.78-3.71,43.68,43.68,0,0,0,14.34-10,42.48,42.48,0,0,0,9-14.83,54.94,54.94,0,0,0,0-36.33,42.57,42.57,0,0,0-9-14.83,43.68,43.68,0,0,0-14.34-10,46.05,46.05,0,0,0-18.78-3.71,47.23,47.23,0,0,0-18.79,3.71,48.58,48.58,0,0,0-15.07,10,46.62,46.62,0,0,0-10.14,14.83A45.11,45.11,0,0,0,1205.68,258.39Z"/>
                    </svg>
                </Box>

                <Typography component="h1" variant="h1" marginBottom="20px">
                     Our shop is currently undergoing maintenance. <BuildTwoToneIcon color="primary"/>
                </Typography>

                <Typography component="span">
                This is necessary to introduce new features and improve your shopping experience.
                    <br/>
                    Our customer service team remains at your disposal to answer your questions and provide you with our
                    assistance.
                </Typography>
            </Box>
        </>
    )
}

export default Maintenance;