import Box from "@mui/material/Box";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
    ButtonIcape,
    ButtonIcapeGreen,
    ButtonIcapeOutlined,
    ButtonIcapeOutlinedRed,
    ButtonIcapeSoftBlue
} from "../../button/button-icape";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {createFilterOptions} from "@mui/material/Autocomplete";
import {logInConsole, logInConsoleObject} from "../../../tools/debug";
import {ErrorHandling} from "../../../tools/error-handling";
import useAuth from "../../../hooks/useAuth";
import {getOrdersByCompany} from "../../../services/orders.services";

const CalculatorToolbar = (props) => {

    const {t} = useTranslation();
    const {auth} = useAuth();
    const filter = createFilterOptions();
    const isStencilEnabled = true;

    const {
        setConnectToBO,
        customerReferenceChoose,
        openCustomerPriceOffersList, setOpenCustomerPriceOffersList,
        customerReferences, setCustomerReferences,
        loadingForCustomerPriceOffers2, loadingForCustomerPriceOffers,
        isCustomerReferenceFieldEmpty,
        setCustomerReferenceChoose,
        setOpenROAddReferenceDialog,
        setROAddReferenceDialogValue,
        setIsCustomerReferenceFieldEmpty,
        rOAddReferenceDialogValue,
        isPcbRefAlreadyExisting, setIsPcbRefAlreadyExisting,
        addReferenceDialogCallingButton,
        openROAddReferenceDialog, setOpenLoadPcbReferencePopup,
        setRepeatOrdersSelectedAvailable
    } = props.autocomplete
    const {
        quantity, setQuantity,
        panelQuantity, setPanelQuantity,
        unitFormat, setUnitFormat,
        setPartNumber,
        isPcbAreaOversized,
        lockCustomPanelizationFields,
        isLockFields,
        repeatOrderMode,
        pcbDeliveryFormat,
    } = props.field

    const {
        handleAddNewReference,
        handleDownloadQuote,
        handleNewProject,
        handleOrderNow,
        handleUnlockFields,
        updateRepeatOrder,
    } = props.function


    const [isProjectReferenceDialogOpen, setIsProjectReferenceDialogOpen] = useState(false);
    const [isResetFieldsDialogOpen, setIsResetFieldsDialogOpen] = useState(false);


    // HANDLE REFERENCE
    const handleChangeROAddReferenceDialog = (e) => {
        setROAddReferenceDialogValue({
            ...rOAddReferenceDialogValue,
            title: e.target.value,
        });
        setCustomerReferenceChoose(e.target.value);
        // setCustomerReferenceChoose(rOAddReferenceDialogValue);
    };
    const handleCancelNewReference = () => {
        setCustomerReferenceChoose({title: '', isNewReference: false, isOrdered: false});
        setPartNumber('');
        setROAddReferenceDialogValue({title: '', isNewReference: false, isOrdered: false});
        setOpenROAddReferenceDialog(false);
        setIsPcbRefAlreadyExisting(false);
    };


    // QUANTITY CHECKS
    const handleQuantityCheck = (_quantity) => {

    }

    return(
        <>
            <Box className={"calculator-toolbar"}>
                {/*HELPER ICON INFO*/}
                <Tooltip title={t("common.moduleInfo")}>
                    <IconButton className="tooltip-icon-purple"
                                onClick={() => setIsProjectReferenceDialogOpen(true)}>
                        <InfoTwoToneIcon />
                    </IconButton>
                </Tooltip>

                {/*HELPER PROJECT REF POPUP MODAL*/}
                <Dialog open={isProjectReferenceDialogOpen} onClose={ () => setIsProjectReferenceDialogOpen(false)}>
                    <DialogTitle>
                        {t("popup.projectReferenceHelper.title")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("popup.projectReferenceHelper.content")}
                        </DialogContentText>
                        <Box className='icon-description-wrapper'>
                            <Box className='color-blue'>
                                <ArticleIcon/>
                            </Box>
                            {t("popup.projectReferenceHelper.quoteInfo")}
                        </Box>
                        <Box className='icon-description-wrapper'>
                            <Box className='color-green'>
                                <CreditScoreIcon/>
                            </Box>
                            {t("popup.projectReferenceHelper.orderInfo")}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <ButtonIcapeSoftBlue onClick={ () => setIsProjectReferenceDialogOpen(false) }>
                            {t("cta.close")}
                        </ButtonIcapeSoftBlue>
                    </DialogActions>
                </Dialog>

                {/*AUTOCOMPLETE PROJECT REF SEARCH*/}
                <Autocomplete
                    id="repeatOrdersReferences"
                    value={customerReferenceChoose}
                    open={openCustomerPriceOffersList}
                    onOpen={() => {
                        setOpenCustomerPriceOffersList(true);
                        setCustomerReferences([]);
                    }}
                    onClose={() => setOpenCustomerPriceOffersList(false)}
                    options={
                        customerReferences.map(ref => {
                            return {
                                title: ref.pcbPartNumber,
                                isNewReference: false,
                                isOrdered: ref.isOrdered,
                                isCsQuote: ref.offerType === 'internal',
                                isValid: ref.isValid
                            }
                        })
                    }
                    renderInput={(params) =>
                        <TextField error={isCustomerReferenceFieldEmpty} {...params}
                                   label={t("calculators.toolbar.projectReference")}
                                   sx={{width: '100%'}}
                                   InputProps={{
                                       ...params.InputProps,
                                       endAdornment: (
                                           <React.Fragment>
                                               {loadingForCustomerPriceOffers2 ?
                                                   <CircularProgress color="inherit" size={20}
                                                                     sx={{ marginRight: "0" }} /> : null}
                                               {params.InputProps.endAdornment}
                                           </React.Fragment>
                                       ),
                                   }} />}
                    onChange={(e, newValue) => {
                        if (newValue === null) {
                            setCustomerReferenceChoose({ title: '' });
                            setPartNumber('');
                            setIsCustomerReferenceFieldEmpty(true);
                        }

                        if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                                setOpenROAddReferenceDialog(true);
                                setROAddReferenceDialogValue({ ...newValue });
                                setCustomerReferenceChoose({ ...newValue, isNewReference: false});
                            });
                        }
                        else if (newValue && newValue.inputValue) {
                            setCustomerReferenceChoose({ ...newValue, isNewReference: true});
                            setPartNumber(newValue.inputValue);
                            setIsCustomerReferenceFieldEmpty(false);
                        }
                        else {
                            if (newValue !== null) {
                                // CUSTOMER CHOSE AN EXISTING ONE
                                if(newValue.isOrdered){
                                    let customerOrders = [];

                                    let config = {headers: {"Content-Type": "application/Id+json"}};
                                    logInConsole(`Getting customers orders from DB`, 'chocolate');

                                    (async () => {
                                        setConnectToBO(true);
                                        try {
                                            customerOrders = await getOrdersByCompany(auth.customerId);

                                            if (customerOrders && customerOrders.length > 0) {
                                                logInConsole(`Filtering orders on part number selected`, 'chocolate');
                                                const customerOrdersFiltered = customerOrders.filter(item => item.pcbPartNumber === newValue.title);

                                                logInConsoleObject(customerOrdersFiltered);

                                                setRepeatOrdersSelectedAvailable(customerOrdersFiltered);
                                                setCustomerReferenceChoose({ ...newValue, isNewReference: false });
                                                setOpenLoadPcbReferencePopup(true);
                                                setIsCustomerReferenceFieldEmpty(false);
                                            }
                                            else {
                                                logInConsole(`No customer orders found (empty)`, 'orange');
                                            }
                                        }
                                        catch (error) {
                                            const resMessage = (error.response && error.response.data && error.response.data.message) ||
                                                error.message || error.toString();
                                            logInConsole(`--->  ${resMessage}`, 'red');
                                            // setErrorBackendConnection(true);
                                            ErrorHandling(null, resMessage);
                                        }
                                        finally {
                                            setConnectToBO(false);
                                        }
                                    })();
                                }
                                else {
                                    setCustomerReferenceChoose({ ...newValue, isNewReference: false });
                                    setOpenLoadPcbReferencePopup(true);
                                    setIsCustomerReferenceFieldEmpty(false);
                                }
                            }
                        }
                    }}
                    filterOptions={(options, params) => {
                        const { inputValue } = params;
                        const filtered = filter(options, params);

                        // IKRAM PREVIOUS WORK
                        //const isInputEmpty = inputValue === '';
                        //const filtered = isInputEmpty ? options : options.filter(option => option.title.toLowerCase().trim() === inputValue.toLowerCase().trim());

                        const isExisting = options.some((option) => option.title.toLowerCase().trim() === inputValue.toLowerCase().trim());

                        // Suggest the creation of a new value
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                title: `${t("cta.add")} "${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    getOptionLabel={(option) => {
                        // e.g. value selected with enter, right from the input

                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.title;
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>

                                    <span style={{
                                        color: option.isOrdered ? '#94CB6E' : '#007dc8',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: '5px'
                                    }}>
                                        {option.isOrdered ? <CreditScoreIcon/> : <ArticleIcon/>}
                                    </span>

                            {option?.title}
                        </li>
                    )}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    autoHighlight
                    sx={{ flexGrow: '2', minWidth: '300px' }}
                    loading={loadingForCustomerPriceOffers}
                    // TODO TRANS
                    loadingText={loadingForCustomerPriceOffers2 ? t("common.loading") : "No references"}
                    disabled={isLockFields}
                    getOptionDisabled={(option) => {
                        if (option.isValid === undefined){
                            return false
                        }
                        return (option.isCsQuote || !option.isValid) && !option.isOrdered;
                    }}
                />

                {/*QUANTITY FIELD*/}
                <TextField
                    id="quantity"
                    label={t("calculators.toolbar.quantity")}
                    value={quantity}
                    onChange={(e) => {
                        setQuantity(parseInt(e.target.value));
                        updateRepeatOrder();
                    }}
                    variant="outlined"
                    type="number"
                    required
                    disabled={(pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') || lockCustomPanelizationFields}
                    error={ isPcbAreaOversized }
                    sx={{flexGrow: '1', maxWidth: '150px'}}
                />

                {/* PANEL QUANTITY */}
                { (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                    <TextField
                        id="panelQuantity"
                        label={t("calculators.step1.fields.panelQuantity")}
                        value={panelQuantity}
                        onChange={e => {
                            setPanelQuantity(parseInt(e.target.value));
                            updateRepeatOrder();
                        }}
                        variant="outlined"
                        margin="normal"
                        type="number"
                        required
                        error={ isPcbAreaOversized }
                        sx={{flexGrow: '1', maxWidth: '150px'}}
                    />
                }

                {/*MM / INCH*/}
                <ToggleButtonGroup
                    color="primary"
                    value={unitFormat}
                    exclusive
                    onChange={e => setUnitFormat(e.target.value)}
                    aria-label="Platform"
                    disabled={isLockFields}
                >
                    <ToggleButton value="mm">{t("calculators.toolbar.mm")}</ToggleButton>
                    <ToggleButton value="in">{t("calculators.toolbar.in")}</ToggleButton>
                </ToggleButtonGroup>

                {/* ICONS BUTTONS */}
                <Box sx={{display: "flex", gap:"10px"}}>

                    {/* UNLOCK FIELDS */}
                    {isLockFields &&
                        <Tooltip title={t("cta.unlockFields")}>
                            <IconButton className="tooltip-icon-blue"
                                        onClick={() => handleUnlockFields()}>
                                <LockOpenTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    {/* NEW PCB */}
                    <Tooltip title={t("common.resetFields")}>
                        <IconButton className="tooltip-icon-red"
                                    onClick={() => setIsResetFieldsDialogOpen(true)}>
                            <DeleteTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* POPUP ADD NEW REFERENCE */}
                <Dialog open={openROAddReferenceDialog}>
                    <DialogTitle>{t("popup.newRef.title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{
                            marginBottom: '20px'
                        }}>
                            {t("popup.newRef.content")}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={rOAddReferenceDialogValue.title}
                            onChange={handleChangeROAddReferenceDialog}
                            label={t("calculators.toolbar.projectReference")}
                            type="text"
                            variant="standard"
                            helperText={isPcbRefAlreadyExisting && "This reference already exist"}
                            error={isPcbRefAlreadyExisting}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ButtonIcapeOutlined onClick={handleCancelNewReference}>
                            {t("cta.cancel")}
                        </ButtonIcapeOutlined>

                        {addReferenceDialogCallingButton !== 'orderNow' && addReferenceDialogCallingButton !== 'downloadQuote' ?
                            <ButtonIcape onClick={handleAddNewReference}>
                                {t("cta.add")}
                            </ButtonIcape> : ''}

                        {addReferenceDialogCallingButton === 'downloadQuote' ?
                            <ButtonIcapeGreen
                                id="downloadQuote"
                                onClick={e => handleDownloadQuote(e.target.id)}>
                                {t("cta.downloadMyQuote")}
                            </ButtonIcapeGreen> : ''}

                        {addReferenceDialogCallingButton === 'orderNow' ?
                            <ButtonIcape
                                id="orderNowCta"
                                onClick={e => handleOrderNow(e)}>
                                {t("cta.orderNow")}
                            </ButtonIcape> : ''}

                    </DialogActions>
                </Dialog>

                {/*RESET ALL DATA POPUP MODAL*/}
                <Dialog open={isResetFieldsDialogOpen} onClose={ () => setIsResetFieldsDialogOpen(false)}>
                    <DialogTitle>
                        {t("popup.resetFields.title")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("popup.resetFields.content")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ButtonIcapeOutlinedRed onClick={() => {
                            handleNewProject();
                            setIsResetFieldsDialogOpen(false);
                        }}>
                            {t("cta.reset")}
                        </ButtonIcapeOutlinedRed>
                        <ButtonIcapeSoftBlue onClick={ () => setIsResetFieldsDialogOpen(false) }>
                            {t("cta.cancel")}
                        </ButtonIcapeSoftBlue>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

export default CalculatorToolbar